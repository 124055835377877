<template>
<div class="container">
  <div class="title">
    <div>水箱数据监测</div>
    <div class="equipment">
      <img src="../assets/img/equip-icon.png" alt="">
      <span>NO. CD102</span>
    </div>
<!--    <div class="time">-->
<!--      <img src="../assets/img/time-icon.png" alt="">-->
<!--      <span>{{ nowDateList }} &nbsp;&nbsp; {{ nowDateWeek }} &nbsp;&nbsp; {{ nowDate }}</span>-->
<!--    </div>-->
  </div>

  <div class="content-box" >
    <div class="content-li" :style="{'color': formDate.ph === null ? '#4D87F8' : Number(formDate.ph) < Number(formDate.phDown) ? '#FA5744' : Number(formDate.ph) > Number(formDate.phUp) ? '#FA5744' : '#4D87F8', 'border': formDate.ph === null ? `1px solid ${'#8BB3FF'}` : !formDate.ph ? `1px solid ${'#8BB3FF'}` : (Number(formDate.ph) <= Number(formDate.phUp) && Number(formDate.ph) >= Number(formDate.phDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background': formDate.ph === null ? '#ECF7FF' : !formDate.ph ? '#ECF7FF' : (Number(formDate.ph) <= Number(formDate.phUp) && Number(formDate.ph) >= Number(formDate.phDown)) ? '#ECF7FF' : '#FFEFEF'}">
      <div class="name" :style="{'color': formDate.ph === null ? '#4D87F8' : Number(formDate.ph) < Number(formDate.phDown) ? '#FA5744' : Number(formDate.ph) > Number(formDate.phUp) ? '#FA5744' : '#4D87F8'}">PH值</div>
      <div class="number" :style="{'color': formDate.ph === null ? '#4D87F8' : Number(formDate.ph) < Number(formDate.phDown) ? '#FA5744' : Number(formDate.ph) > Number(formDate.phUp) ? '#FA5744' : '#4D87F8'}">{{ formDate.ph ? Number(formDate.ph) : '--' }}</div>
      <img v-if="formDate.phDown === null || formDate.ph === null || !formDate.ph || (Number(formDate.ph) >= Number(formDate.phDown) && Number(formDate.ph) <= Number(formDate.phUp))" src="../assets/img/ph1.png" alt="">
      <img v-else src="../assets/img/ph2.png" alt="">
      <div class="scope" :style="{'color': formDate.ph === null ? '#4D87F8' : Number(formDate.ph) < Number(formDate.phDown) ? '#FA5744' : Number(formDate.ph) > Number(formDate.phUp) ? '#FA5744' : '#4D87F8'}">({{ formDate.phDown }}-{{ formDate.phUp }})</div>
    </div>
    <div class="content-li" :style="{'color':formDate.wd === null ? '#4D87F8' : Number(formDate.wd) < Number(formDate.temperaturDown) ? '#FA5744' : Number(formDate.wd) > Number(formDate.temperaturUp) ? '#FA5744' : '#4D87F8', 'border': formDate.wd === null ? `1px solid ${'#8BB3FF'}` : !formDate.wd ? `1px solid ${'#8BB3FF'}` : (Number(formDate.wd) <= Number(formDate.temperaturUp) && Number(formDate.wd) >= Number(formDate.temperaturDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background' :formDate.wd === null ? '#ECF7FF' : !formDate.wd ? '#ECF7FF' : (Number(formDate.wd) <= Number(formDate.temperaturUp) && Number(formDate.wd) >= Number(formDate.temperaturDown)) ? '#ECF7FF' : '#FFEFEF' }">
      <div class="name" :style="{'color':formDate.wd === null ? '#4D87F8' : Number(formDate.wd) < Number(formDate.temperaturDown) ? '#FA5744' : Number(formDate.wd) > Number(formDate.temperaturUp) ? '#FA5744' : '#4D87F8',}">水温</div>
      <div class="number" :style="{'color':formDate.wd === null ? '#4D87F8' : Number(formDate.wd) < Number(formDate.temperaturDown) ? '#FA5744' : Number(formDate.wd) > Number(formDate.temperaturUp) ? '#FA5744' : '#4D87F8',}">{{ formDate.wd ? Number(formDate.wd) : '--' }}</div>
      <img v-if="formDate.temperaturDown === null || formDate.wd === null || !formDate.wd || (Number(formDate.wd) >= Number(formDate.temperaturDown) && Number(formDate.wd) <= Number(formDate.temperaturUp))" src="../assets/img/sw1.png" alt="">
      <img v-else src="../assets/img/sw2.png" alt="">
      <div class="scope" :style="{'color':formDate.wd === null ? '#4D87F8' : Number(formDate.wd) < Number(formDate.temperaturDown) ? '#FA5744' : Number(formDate.wd) > Number(formDate.temperaturUp) ? '#FA5744' : '#4D87F8',}">({{ formDate.temperaturDown }}℃-{{ formDate.temperaturUp }}℃)</div>
    </div>
    <div class="content-li" :style="{'color': formDate.ddl === null ? '#4D87F8' : Number(formDate.ddl) < Number(formDate.electricityDown) ? '#FA5744' : Number(formDate.ddl) > Number(formDate.electricityUp) ? '#FA5744' : '#4D87F8', 'border': formDate.ddl === null ? `1px solid ${'#8BB3FF'}` : !formDate.ddl ? `1px solid ${'#8BB3FF'}` : (Number(formDate.ddl) <= Number(formDate.electricityUp) && Number(formDate.ddl) >= Number(formDate.electricityDown)) ? `1px solid ${'#8BB3FF'}` : `1px solid ${'#FA5744'}`,'background': formDate.ddl === null ? '#ECF7FF' : !formDate.ddl ? '#ECF7FF' : (Number(formDate.ddl) <= Number(formDate.electricityUp) && Number(formDate.ddl) >= Number(formDate.electricityDown)) ? '#ECF7FF' : '#FFEFEF' }">
      <div class="name" :style="{'color': formDate.ddl === null ? '#4D87F8' : Number(formDate.ddl) < Number(formDate.electricityDown) ? '#FA5744' : Number(formDate.ddl) > Number(formDate.electricityUp) ? '#FA5744' : '#4D87F8'}">电导率</div>
      <div class="number" :style="{'color': formDate.ddl === null ? '#4D87F8' : Number(formDate.ddl) < Number(formDate.electricityDown) ? '#FA5744' : Number(formDate.ddl) > Number(formDate.electricityUp) ? '#FA5744' : '#4D87F8'}">{{ formDate.ddl ? Number(formDate.ddl) : '--' }}</div>
      <img v-if="formDate.electricityDown === null || formDate.ddl === null || !formDate.ddl || (Number(formDate.ddl) >= Number(formDate.electricityDown) && Number(formDate.ddl) <= Number(formDate.electricityUp))" src="../assets/img/ddl1.png" alt="">
      <img v-else src="../assets/img/ddl2.png" alt="">
      <div class="scope" :style="{'color': formDate.ddl === null ? '#4D87F8' : Number(formDate.ddl) < Number(formDate.electricityDown) ? '#FA5744' : Number(formDate.ddl) > Number(formDate.electricityUp) ? '#FA5744' : '#4D87F8'}">({{ formDate.electricityDown }}us/cm-{{ formDate.electricityUp }}us/cm)</div>
    </div>
  </div>
</div>
</template>

<script>
import API from '@/api';
export default {
name: "waterTankMonitoring",
  data() {
  return {
    nowDate:'',
    nowDateList: '',
    nowDateWeek: '',
    //deviceId
    formDate: {
      phUp: null,
      ph: null,
      phDown: null,
      temperaturUp: null,
      wd:null,
      temperaturDown: null,
      electricityUp: null,
      ddl: null,
      electricityDown: null
    }
  }
  },
  created() {
  this.getDate()
    this.currentTime()
    this.getFormDate()
    this.currentTime2()
  },
  methods: {
    getDate() {
      return new Promise((resolve, reject) => {
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = date.getMonth() + 1; // 月
        let day = date.getDate(); // 日
        let hour = date.getHours(); // 时
        let week = date.getDay(); // 星期
        let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
        hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
        let minute = date.getMinutes(); // 分
        minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
        let second = date.getSeconds(); // 秒
        second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
        this.nowDate = `${hour}:${minute}:${second}`;
        this.nowDateList = `${year}.${month}.${day}`;
        this.nowDateWeek = `${weekArr[week]}`;
        resolve(); // 解决Promise
      });
    },
    getFormDate() {
      API.waterDeviceInfo({deviceId: this.$route.query.deviceId}).then(res => {
        console.log(res)
        this.formDate = res.message.data
      })
    },
    currentTime(){
      const dateTime = () => {
        this.getDate().then(() => {
          setTimeout(dateTime, 1000);
          console.log(this.nowDate);
        });
      };
      dateTime(); // 第一次调用
    },
    currentTime2(){
      setInterval(()=>{
        this.getFormDate()
      },10000)
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  background: linear-gradient(180deg, #E9EFF5 0%, #E4EEFA 100%);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.title {
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  position: relative;
  //border-bottom: 1px solid #dcdddd;
  color: #3268A6;
  //background: -webkit-linear-gradient(#3268A6,#152E66);
  ////-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  line-height: 60px;
  background-image: url("~@/assets/img/waterTitle.png");
  background-size: 100% 100%;
  //background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
div{
  background: -webkit-linear-gradient(#3268A6,#152E66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
}
.equipment {
  position: absolute;
  color: #224882;
  font-size: 18px;
  top: -8px;
  left: 50px;
  display: flex;
  font-weight: normal;
  align-items: center;
  img {
    width: 15px;
    height: 17px;
    margin-right: 10px;
  }
}
.time {
  position: absolute;
  color: #224882;
  font-size: 18px;
  top: -8px;
  right: 50px;
  display: flex;
  font-weight: normal;
  align-items: center;
  img{
    width: 17px ;
    height: 17px;
    margin-right: 10px;
  }
}
.content-box {
  padding: 35px 50px 55px;
  display: flex;
  justify-content: space-between;
}
.content-li {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);
  background: linear-gradient(180deg, #E4EEFC 0%, #F0F5FB 100%);
  border-radius: 6px;
  width: 31.5%;
  text-align: center;
  font-weight: bold;
  //height: 86vh;
  .name {
    font-size: 40px;
    line-height: 144px;
    color: #224882 ;
    font-weight: bold;
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .number {
    font-size: 160px;
    color: #146BF8;
    padding-bottom: 80px;
  }
  img {
    width: 90px;
    height: 91px;
  }
  .scope {
    font-size: 40px;
    line-height: 144px;
    color: #224882;
    padding-top: 40px;
    padding-bottom: 70px;
  }
}
</style>
